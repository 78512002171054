import CameraHolder from "../components/CameraHolder";

import "../styles/cameraViewer.css";
import {useEffect, useState} from "react";
import {decodeToken, getCookie, isTokenExpired} from "../utils/tokenFunctions";
export default function CamaraViewer() {
    const [isLogin,setIsLogin] = useState(null);

    useEffect(()=>{
        const token = getCookie("sessionToken");
        if(token){
            const payload = decodeToken(token);
            if(payload && !isTokenExpired(payload.exp)){
                    setIsLogin(true);
                }else{
                    setIsLogin(false)
                }
            } else{
            setIsLogin(false)
        }
    },[])

    useEffect(() => {
        if (isLogin === false) {
            goToMain();
        }
    }, [isLogin]);


    return (
        <>
            {isLogin && (
            <main className="main-content">
                    <h2>Camaras de seguridad</h2>
                    <div className={"CameraViewer"}>
                        <CameraHolder videoId={"1fiF7B6VkCk"}></CameraHolder>
                        <CameraHolder videoId={"3LXQWU67Ufk"}> </CameraHolder>
                        <CameraHolder videoId={"z7SiAaN4ogw"}></CameraHolder>
                        <CameraHolder videoId={"p0Qhe4vhYLQ"}></CameraHolder>
                    </div>
            </main>
            )}
        </>
    );}

function goToMain(){
    window.location.href = `/`;
}