import Valor from "../components/Valor";
import Sponsors from "../components/Sponsors";

export default function PaginaPricipal(){
    return(
        <>
            <main className="main-content">
                <img src={"simplelogoCICI.png"} alt={"Logo de CICI"}></img>
                <h3 id={"cici-lema"}>"Cuidamos cada paso con amor y dedicación"</h3>
                <section className={"div-descripcion"}>
                    <p>Bienvenidos a CICI, un centro de primera infancia dedicado a atender las necesidades e
                    intereses únicos de cada niño y niña, así como de sus familias. Inspirados en el método
                    Montessori, nos enfocamos en fomentar la autonomía, la curiosidad y el aprendizaje a
                    través de la exploración libre en un entorno preparado. Este enfoque permite a los niños
                    desarrollar habilidades cognitivas, emocionales y sociales a su propio ritmo, respetando su
                    individualidad.
                    </p>
                    <p>Nuestro equipo pedagógico, altamente capacitado y comprometido, acompaña a los niños en un ambiente seguro y acogedor, promoviendo el crecimiento integral en una etapa clave de su desarrollo.
                    </p>
                </section>
                <div className={"contenedor-infoAdicional"}>
                    <Valor ttlValue = {"Días y horarios de funcionamiento"} txtContent = {"• Lunes a Viernes de 7:00 a 19:00 horas" +
                        "<br/> • Sábados de 7:00 a 13:00 horas"}></Valor>
                    <Valor ttlValue = {"Formas de pago"} txtContent = {"• Transferencia Bancaria, Depósito bancario o en efectivo."}></Valor>
                    <Valor ttlValue = {"Ubicación"} txtContent = {"• Esquina Rincón y Rivera 1605"}></Valor>
                </div>
                <Sponsors></Sponsors>
            </main>
        </>
    )}