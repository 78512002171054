import {useEffect} from "react";
import MisionVision from "./MisionVision";
import Valor from "../components/Valor";
import "../styles/values.css"

export default function AboutUs(){
    useEffect(() => {
        const screenWidth = window.innerWidth;
        if(screenWidth < 700){

        }
    }, []);

    return(
        <>
            <main className="main-content">
                <div className={"sobre-nosotros"}>
                    <h3>Sobre nosotros</h3>
                    <p>Somos una empresa dedicada al cuidado, entretenimiento y bienestar de niños, con el objetivo de brindar seguridad y facilitar el aprendizaje e integración mediante un servicio de guardería y diversos talleres</p>
                </div>
                <MisionVision></MisionVision>
                <div className={"our-values"}>
                    <h3>Nuestros valores</h3>
                    <div className={"contenedor-valores"}>
                        <Valor ttlValue = {"Disciplina"} txtContent = {"Es un valor fundamental para la empresa, esto guía a las personas a lograr lo que se proponen y al ser un espacio para niños, ellos aprenderán sobre la misma."}></Valor>
                        <Valor ttlValue = {"Respeto"} txtContent = {"En todo momento se debe cumplir ya que los niños tienen que aprenderlo y además no se puede trabajar en un lugar en el que no exista este valor."}></Valor>
                        <Valor ttlValue = {"Compromiso"} txtContent = {"Todos los empleados se deben comprometer con la empresa y, así mismo los padres ya que estos deben poner de su parte para que la empresa logre lo que se propone."}></Valor>
                        <Valor ttlValue = {"Equidad"} txtContent = {"Es de gran importancia, ya que cada trabajador, niño y padre tendrá o accedes a las mismas cosas que los demás."}></Valor>
                        <Valor ttlValue = {"Tolerancia"} txtContent = {"Los trabajadores deberán tener este valor muy en cuenta, ya que para cuidar niños deben mantenerlo siempre presente."}></Valor>
                        <Valor ttlValue = {"Responsabilidad"} txtContent = {"Debe ser por parte tanto de la empresa como de los padres, ya que de este modo los niños lo aprenderán.\n"}></Valor>
                        <Valor ttlValue = {"Confianza"} txtContent = {"Es fundamental para la empresa, la misma debe generarla con los padres para que estos sientan tranquilidad al dejar a sus hijos en la empresa.\n"}></Valor>
                    </div>
                </div>

            </main>
        </>
    )}