export default function Sponsors(props){
    return(
        <div className={"sponsors"}>
            <h3>Nuestros Sponsors</h3>
            <div className={"sponsors-logos"}>
                <img src={"cafesponsor.png"}></img>
                <img src={"fresaylima.png"}></img>
                <img src={"laciguenia.png"}></img>
            </div>
        </div>
    );
}