// Función para obtener el valor de una cookie por su nombre (si el token está en cookies)
export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

// Función para decodificar el JWT y obtener el payload
export function decodeToken(token) {
    if (!token) return null;
    try {
         // Decodificar el payload
        return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
        console.error("Invalid token", error);
        return null;
    }
}

// Función para verificar si el token ha expirado
export function isTokenExpired(exp) {
    const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
    return exp < currentTime;
}

export function deleteToken(name){
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}