import UserInfo from "./UserInfo";
import LoginForm from "../components/LoginForm";
import {useEffect, useState} from "react";
import {decodeToken, getCookie, isTokenExpired} from "../utils/tokenFunctions";

export default function Header({ children }) {
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [isLogin,setIsLogin] = useState(false);

    useEffect(()=>{
        const token = getCookie("sessionToken");
        if(token){
            const payload = decodeToken(token);
            if(payload){
                if(!isTokenExpired(payload.exp)){
                    setIsLogin(true);
                }
            }
        }
    },[])

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <header className={"header"}>
                <div className="site-heather-left" onClick={goToMain}>
                    <img id={"logo"} alt={"logo de CICI"} src={"logoGuarderia.png"}/>
                    {/*<h2 id="titulo-guarderia">Guarderia sin nombre</h2>*/}
                </div>
                <div className={"site-heather-right"}>
                    <img src={"securityCam.svg"} alt={"Camara de seguridad"} onClick={
                        isLogin
                            ? goToCameras
                            : openModal
                    } style={{cursor: "pointer"}}/>
                    <UserInfo IsModalOpen={isModalOpen}  onCloseModal={closeModal} onOpenModal={openModal}></UserInfo>
                </div>
                <LoginForm IsModalOpen={isModalOpen}  onCloseModal={closeModal}/> {/* El formulario de login */}
        </header>

    );}

function goToMain(){
    window.location.href = `/`;
}

function goToCameras(){
    window.location.href = `/view-cameras`;
}