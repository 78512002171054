export default function Inscripciones(){

    return(
        <>
            <main className="main-content">
                <section className={"inscripciones"}>
                    <h4>¿Qué documentación debemos presentar para la inscripción?</h4>
                    <ul>
                        <li>Ficha de inscripción</li>
                        <li>Fotocopia de cédula de niñ@ y núcleo familiar</li>
                        <li>Fotocopia de carné de vacunas (Vigente)</li>
                        <li>Fotocopia de aptitud física (Carné de Salud pág.24)</li>
                        <li>Fotocopia de la cédula de toda persona autorizada a retirar al niñ@ del jardín</li>
                        <li>Contrato de responsabilidad firmado</li>
                        <li>Comprobante de pago de matricula</li>
                    </ul>
                    <br></br>
                    <h4>¿Cómo hacemos la inscripción?</h4>
                    <ul>
                        <li>Abonar la matrícula y enviar el comprobante al correo.</li>
                        <li>Presentar la documentación solicitada.</li>
                        <li>Agendarse para una entrevista con la maestra referente que atenderá al niño/a.</li>
                    </ul>
                </section>
            </main>
        </>
    )}