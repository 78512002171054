export default function CameraHolder (props){
    return(
        <>
            <iframe
                className="CameraHolder"
                src={`https://www.youtube.com/embed/${props.videoId}`}
                title="Camera video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
            </iframe>
        </>
    );
}