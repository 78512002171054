import {Link} from "react-router-dom";
import {useState} from "react";

export default function NavBar({ children }) {
    const [mobileScreen, setMobileScreen] = useState(false);

    return (
        <nav className="nav-bar" id={"nav-bar"}>
            {mobileScreen && <button id="menu-toggle">☰</button>}
            <ul className="menu-list">
                <li><Link to="/">Inicio</Link></li>
                <li><Link to="/about-us">Sobre Nosotros</Link></li>
                <li><Link to="/inscripciones">Inscripciones</Link></li>
                <li><Link to="/activities">Actividades</Link></li>
                <li><Link to="/contact">Contacto</Link></li>
            </ul>
        </nav>
    );}