import {useEffect} from "react";

export default function MisionVision(){

    useEffect(() => {
        const screenWidth = window.innerWidth;
        if(screenWidth < 700){

        }
    }, []);

    return(
        <>
            <section className={"mision-vision"}>
                <div>
                    <h2>Misión</h2>
                    <p>Ofrecer un entorno seguro y acogedor donde los niños puedan socializar, aprender y
                        desarrollar habilidades esenciales para su crecimiento, mientras brindamos a los padres la
                        tranquilidad de saber que sus hijos están en manos confiables y en un espacio pensado
                        para su bienestar.
                    </p>
                </div>
                <div>
                    <h2>Visión</h2>
                    <p>Convertirnos en un centro de referencia para el cuidado e integración infantil, reconocido
                        por nuestra excelencia en el servicio, nuestro equipo de profesionales altamente
                        capacitados y el ambiente seguro y confiable que brindamos a las familias.</p>
                </div>
            </section>
        </>
    )}