import "../utils/tokenFunctions"
import Modal from 'react-modal';
import {useEffect, useState} from "react";
import {decodeToken, deleteToken, getCookie, isTokenExpired} from "../utils/tokenFunctions";

Modal.setAppElement('#root');

export default function UserInfo(props) {
    const [nombreUsuario, setNombreUsuario] = useState("Iniciar Sesion");
    const [isLogin,setIsLogin] = useState(false);

    useEffect(()=>{
        const token = getCookie("sessionToken");

        if(token){
            const payload = decodeToken(token);
            if(payload){
                if(!isTokenExpired(payload.exp)){
                    setNombreUsuario(payload.n +" "+ payload.a);
                    setIsLogin(true);
                }
            }
        }
    },[])

    const openModal = () => {
        props.onOpenModal();
    };

    const borrarCookie = () => {
        deleteToken("sessionToken");
        window.location.reload();
    }


    return (
        <>
            <div className={"userInfo"}>
                <img src={"userPhoto.svg"} alt={"Perfil del usuario"}></img>
                {isLogin
                ? <><p>{nombreUsuario}</p> <button onClick={borrarCookie}>Cerrar Sesión</button></>
                : <button onClick={openModal}>Iniciar sesión</button>
                }
            </div>
        </>
    );}