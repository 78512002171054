import {useLocation} from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group";

import "../styles/transition.css"

function TransitionElement({ children }) {
    const location = useLocation();
    return (
        <>
            <TransitionGroup component={null}>
                <CSSTransition
                    key={location.key}  // Clave única para las rutas
                    timeout={500}  // Duración de la animación en ms
                    classNames="fade"
                    unmountOnExit
                    mountOnEnter>
                    <div>
                        {children}
                    </div>

                </CSSTransition>
            </TransitionGroup>
        </>
    );
}

export default TransitionElement;