import {useEffect, useState} from "react";
import "../styles/ContactForm.css"
import {decodeToken, getCookie, isTokenExpired} from "../utils/tokenFunctions";
import RedesSociales from "../components/RedesSociales";

export default function ContactForm(){

    const [nombre,setNombre] = useState('');
    const [email,setEmail] = useState('');
    const [mensaje,setMensaje] = useState('');
    const [mobileScreen, setMobileScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setMobileScreen(true);
            } else {
                setMobileScreen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(()=>{
        const token = getCookie("sessionToken");
        if(token){
            const payload = decodeToken(token);
            if(payload && !isTokenExpired(payload.exp)){
                setNombre(payload.n+" "+payload.a);
                setEmail(payload.e);
            }
        }
    },[])


    const handleSend = (event)=>{
        event.preventDefault();

        if(!nombre || !email || !mensaje){
            alert("Se deben completar todos los campos");
            return;
        }
        sendEmail(nombre,email,mensaje);
    }

    useEffect(() => {
        const screenWidth = window.innerWidth;
        if(screenWidth < 700){

        }
    }, []);

    return(
        <>
            <main className="main-content">
                <div className={"contact-container"}>
                    {!mobileScreen && <img src={"contactoimg.webp"} className={"contactImg"} alt={"Imagen representativa de oficina"}></img>}
                    <section className={"contact-section"}>
                        <h3>Contacto</h3>
                        <div className={"info"}>
                            <p>¿Deseas tener mayor información sobre el centro? No dudes en comunicarte y te contactaremos lo antes posible</p>
                            <a href={"mailto:cicicuidadodeinfantes@gmail.com"} style={{cursor: "pointer"}}><b>Correo electónico:</b> cicicuidadodeinfantes@gmail.com</a>
                            <p><b>Teléfono:</b> 094433733</p>
                            <p><b>Ubicacion:</b> Rincón y Rivera 1605</p>
                            <p>Young, Rio Negro</p>
                        </div>

                        <div id={"formulario"}>
                            <form id={"formContacto"}  onSubmit={handleSend}>
                                <label id={"lblnombre"} htmlFor={"nombre"}>Nombre</label>
                                <input type={"text"} id={"nombre"} placeholder={"Ingrese su nombre"} value={nombre} onChange={(e)=> setNombre(e.target.value)} />
                                <label id={"lblcorreo"} htmlFor={"correo"}>Correo electronico</label>
                                <input type={"text"} id={"correo"} placeholder={"Ingrese su correo"} value={email} onChange={(e)=> setEmail(e.target.value)} />
                                <textarea id={"mensaje"} form={"formContacto"} placeholder={"Ingrese su mensaje..."} value={mensaje} onChange={(e)=> setMensaje(e.target.value)} ></textarea>
                                <button type={"submit"}>Enviar</button>
                            </form>
                        </div>
                        <RedesSociales></RedesSociales>
                    </section>
                </div>
            </main>
        </>
    )}


async function sendEmail(nombre,correo,mensaje){
    if(nombre && correo && mensaje){
        const params = JSON.stringify({"nameFrom": nombre, "emailFrom":correo, "msg":mensaje});
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body : params,
        }
        await fetch(process.env.REACT_APP_BASE_URL+'/sendMail',options)
            .then(function(response){
                if(response.status === 200){
                    response.json().then(jsonResponse => {
                        alert(jsonResponse.respuesta);
                    }).catch(error => {
                        console.error("Error al parsear el JSON:", error);
                    });
                }
                else{
                    alert("No se pudo enviar el mensaje");
                }
            })
    }

}