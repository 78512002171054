import {useEffect} from "react";
import Activity from "../components/Activity";
import "../styles/activities.css";

export default function Activities(){

    useEffect(() => {
        const screenWidth = window.innerWidth;
        if(screenWidth < 700){

        }
    }, []);

    return(
        <>
            <main className="main-content">
                <section className={"activities"}>
                    <Activity ttlActivity = {"Recreación"} orden = {"izq"} contColor = "green" txtContent = {"Actividades lúdicas y físicas que permiten a los niños liberar energía, socializar\n" +
                        "y disfrutar de momentos de esparcimiento. Esto promueve el desarrollo de sus habilidades\n" +
                        "sociales, la coordinación motriz y fortalece su autoestima al participar en juegos en grupo"} imgsrc = {"recreacion.jpeg"}></Activity>
                    <Activity ttlActivity = {"Manualidades"} orden = {"der"} contColor = "blue" txtContent = {"Actividad donde los niños crean con sus manos, utilizando diferentes materiales como\n" +
                        "papel, pintura y arcilla. Estas actividades estimulan la creatividad, mejoran la motricidad fina\n" +
                        "y fomentan la concentración, además de darles una forma de expresar sus emociones."} imgsrc = {"manualidades.jpeg"}></Activity>
                    <Activity ttlActivity = {"Psicología"} orden = {"izq"} contColor = "yellow" txtContent = {"Los niños exploran diferentes materiales y texturas, lo que activa sus sentidos y estimula su\n" +
                        "desarrollo sensorial. Estos juegos son esenciales para que los niños reconozcan y\n" +
                        "diferencien texturas, formas y colores, fortaleciendo la percepción táctil y visual."} imgsrc = {"psicologia.jpg"}></Activity>
                    <Activity ttlActivity = {"Juegos con textura y estímulo"} orden={"der"} contColor = {"purple"} txtContent = {"Juegos diseñados para aprender mientras se divierten, que favorecen el desarrollo cognitivo\n" +
                        "y la resolución de problemas. Los niños adquieren nuevas habilidades a través de\n" +
                        "actividades que fomentan el pensamiento lógico, la memoria y la capacidad de\n" +
                        "concentración.\n"} imgsrc = {"juegoConTextura.jpeg"}></Activity>
                    <Activity ttlActivity = {"Juegos didácticos"} orden = {"izq"} contColor = "green" txtContent = {"Actividades que promueven el desarrollo de habilidades motoras gruesas y finas, como\n" +
                        "saltar, correr y manipular objetos. Estas actividades no solo mejoran la coordinación y el\n" +
                        "equilibrio, sino que también ayudan a los niños a tomar conciencia de su cuerpo y el\n" +
                        "espacio.\n"} imgsrc = {"juegosDidacticos.jpg"}></Activity>
                    <Activity ttlActivity = {"Psicomotricidad"} orden = {"der"} contColor = "blue" txtContent = {"Los niños participan en el cuidado de plantas, lo que les enseña sobre la naturaleza y el\n" +
                        "ciclo de la vida. Esta actividad fomenta la paciencia, la responsabilidad y el respeto por el\n" +
                        "medio ambiente, mientras estimula su curiosidad y capacidad de observación.\n"} imgsrc = {"psicomotricidad.jpg"}></Activity>
                    <Activity ttlActivity = {"Jardinería"} orden = {"izq"} contColor = "yellow" txtContent = {"Apoyo emocional y psicológico para que los niños gestionen sus emociones, mejoren su\n" +
                        "autoestima y desarrollen habilidades de comunicación. La intervención temprana en este\n" +
                        "ámbito les ayuda a entender y expresar sus sentimientos, favoreciendo un desarrollo\n" +
                        "emocional equilibrado."} imgsrc = {"jardineria.jpg"}></Activity>
                     <Activity ttlActivity = {"Danza y música:"} orden = {"der"} contColor = "purple" txtContent = {"Una actividad divertida donde los niños exploran el ritmo, la coordinación y\n" +
                        "la creatividad a través de juegos musicales, movimientos guiados y expresión corporal. Esta\n" +
                        "actividad fomenta el desarrollo psicomotor en un ambiente alegre y seguro."} imgsrc = {"musica.jpg"}></Activity>

                </section>
            </main>
        </>
    )}