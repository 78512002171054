import {useEffect, useState} from "react";

export default function Activity(props){
    const [orden, setOrden] = useState(null);
    const [mobileScreen, setMobileScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setMobileScreen(true);
            } else {
                setMobileScreen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        if(props.orden) setOrden(props.orden);
    },[props.orden])


    return(
        <div className={props.orden === 'izq' ? "activity-container-izq" : "activity-container-der"}>
            <div className={`container-${props.contColor}`}>
                <h4>{props.ttlActivity}</h4>
                <p>{props.txtContent}</p>
            </div>
            {!mobileScreen && <img src={`${props.imgsrc}`} className={'activity-img'}/>}
        </div>
    );
}