import Header from "../components/Header";
import NavBar from "../components/NavBar";
import TransitionElement from "../components/TransitionElement";

import "../styles/transition.css"
import RedesSociales from "../components/RedesSociales";

function DefaultLayout({ children }) {
    return (
        <>
            <Header/>
            <NavBar/>

            <TransitionElement>
                {children}
            </TransitionElement>
            {/*<>*/}
            {/*    {children}*/}
            {/*</>*/}
            <footer>
                <p>Consultas o Contacto</p>
                <a href={"mailto:cicicuidadodeinfantes@gmail.com"} style={{cursor: "pointer"}}>cicicuidadodeinfantes@gmail.com</a>
                <p>094433733</p>
                <p>Ubicacion Rincón y Rivera 1605</p>
                <p>Young, Rio Negro</p>
                <RedesSociales></RedesSociales>
            </footer>
        </>
    );
}

export default DefaultLayout;