import {useEffect, useState} from "react";
import Modal from 'react-modal';

Modal.setAppElement('#root');

export default function LoginForm(props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isModalOpen,setIsModalOpen] = useState(false);

    const handleSubmit = (event) =>{
        event.preventDefault();

        if(!email || !password){
            alert("Los campos no pueden estar vacios");
            return
        }

        Login(email,password);
    }

    useEffect(()=>{
        if(props.IsModalOpen) {
            setIsModalOpen(true);
        }else{
            setIsModalOpen(false);
        }

    },[props.IsModalOpen])

    const closeModal = () => {
        setIsModalOpen(false);
        props.onCloseModal();
    };

    return (
        <>
        {/*<Modal*/}
        {/*    isOpen={isModalOpen}*/}
        {/*    onRequestClose={closeModal}*/}
        {/*    contentLabel="Login Modal"*/}
        {/*    className="Modal" // Clase CSS para estilos personalizados*/}
        {/*    overlayClassName="Overlay" // Clase CSS para el fondo del modal*/}
        {/*>*/}

            {isModalOpen &&(<div className={"form-container"}>
                <h2>Iniciar Sesión</h2>
                <form id={"formLogin"} onSubmit={handleSubmit}>
                    <label id={"lblcorreo"} htmlFor={"correo"}>Correo</label>
                    <input type={"text"} id={"correo"} placeholder={"Ingrese el correo"} value={email} onChange={(e)=> setEmail(e.target.value)} />
                    <label id={"lblpassword"} htmlFor={"password"}>Contraseña</label>
                    <input type={"password"} id={"password"} placeholder={"Ingrese contraseña"} value={password}  onChange={(e)=> setPassword(e.target.value)}/>
                    <div className={"button-container"}>
                        <button type={"submit"}>Enviar</button>
                        <button onClick={closeModal}>Cerrar</button> {/* Botón para cerrar el modal */}
                    </div>

                </form>

            </div>)}

        {/*</Modal>*/}
        </>
    );}

async function Login(email,password){
    if(email && password){
        const params = JSON.stringify({"email": email, "password":password});
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body : params,
        }
        await fetch(process.env.REACT_APP_BASE_URL+'/login2',options)
            .then(function(response){
                if(response.status === 200){
                    response.json().then(jsonResponse => {
                        document.cookie = `sessionToken=${jsonResponse.accessToken}`;
                        // eslint-disable-next-line no-restricted-globals
                        location.reload();
                    }).catch(error => {
                        console.error("Error al parsear el JSON:", error);
                    });
                }
                else{
                    //Inicio de sesion fallido
                }
            })
    }

}


