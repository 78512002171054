import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter,RouterProvider} from 'react-router-dom';
import PaginaPricipal from './routes/PaginaPrincipal.js'
import AboutUs from "./routes/AboutUs";
import CamaraViewer from "./routes/CamaraViewer";
import ContactForm from "./routes/ContactForm";
import Activities from "./routes/Activities";
import './index.css';
import reportWebVitals from './reportWebVitals';
import DefaultLayout from "./layout/DefaultLayout";
import Inscripciones from "./routes/Inscripciones";


const router = createBrowserRouter([
    {
        path:"/",
        element:
            <DefaultLayout>
                <PaginaPricipal />
            </DefaultLayout>,
    },
    {
         path:"/about-us",
         element:
             <DefaultLayout>
                 <AboutUs />
             </DefaultLayout>,
     },
    {
        path:"/view-cameras",
        element:
            <DefaultLayout>
                <CamaraViewer />
            </DefaultLayout>,
    },
    {
        path:"/activities",
        element:
            <DefaultLayout>
                <Activities />
            </DefaultLayout>,
    },
    {
        path:"/inscripciones",
        element:
            <DefaultLayout>
                <Inscripciones />
            </DefaultLayout>,
    },
    {
        path:"/contact",
        element: <DefaultLayout> <ContactForm /> </DefaultLayout>,
     },
    ]);

ReactDOM.createRoot(document.getElementById('root')).render(
        <RouterProvider router ={router}/>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
